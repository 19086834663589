<template>
    <div class="modal" :class="{'is-active': opened}">
      <div class="modal-background"></div>
      <div class="modal-card my-modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Upload Wholesale Prices</p>
          <button class="delete" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <p class="title is-4">Select Excel file to update wholesale or promotion prices</p>

          <div class="field">
            <label class="label">Country</label>
            <div class="control">
                <div class="select">
                  <select v-model="country">
                    <option>US</option>
                    <option>Canada</option>
                  </select>
                </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Store</label>
            <div class="control">
                <div class="select">
                  <select v-model="store">
                    <option>Home Depot</option>
                    <option>Wayfair</option>
                  </select>
                </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Price</label>
            <div class="control">
                <div class="select">
                  <select v-model="priceType">
                    <option>Wholesale</option>
                    <option>Promotion</option>
                  </select>
                </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Start date</label>
            <div class="control">
              <datepicker v-model="startDate" :inline="true" name="startdate"></datepicker>
            </div>
          </div>

          <div class="field" v-if="priceType == 'Promotion'">
            <label class="label">End date</label>
            <div class="control">
              <datepicker v-model="endDate" :inline="true" name="enddate"></datepicker>
            </div>
          </div>

          <div class="field">
            <label class="label">Excel file</label>
            <div class="control">
              <label class="file-label">
                <input class="file-input" id="myFileInput" type="file" accept=".xlsx,.xls" @change="onExcelFileChange">
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                </span>
                <span class="file-name">
                  {{excelFile ? excelFile.name : 'Choose a EXCEL file...'}}
                </span>
              </label>
            </div>
          </div>

          <div v-if="finalRecords">
            <label class="label"><span>{{finalRecords.length}}</span> Records to be upload</label>
            <div class="control">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>#</th>
                    <th v-for="(f, i) in dataConfig.fields" :key="'r-h-'+i">{{f}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, j) in finalRecords" :key="'r-r-'+j">
                    <td>{{j+1}}</td>
                    <td v-for="(c, k) in dataConfig.fields" :key="'r-c-'+j+'-'+k">
                      {{r[c]}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="field is-grouped" v-if="finalRecords && finalRecords.length">
            <div class="control">
              <button class="button is-link" @click="sendRecords">Upload Records</button>
            </div>
          </div>
  
          <div v-if="success" class="notification is-success is-light">
            <button class="delete" @click="success=''"></button>
            <div><strong>{{success}}</strong></div>
          </div>
  
          <div v-if="successes.length" class="notification is-success is-light">
            <div>
              <strong>Updated successfully: {{successes.length}}</strong>
              <br/>
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <td v-for="(sf, j) in dataConfig.successFields" :key="'sfh-'+j">{{sf}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(s, i) in successes" :key="'s-'+i">
                    <td v-for="(sf, j) in dataConfig.successFields" :key="'sfd-'+i+'-'+j">
                      {{s[sf]}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
          <div v-if="failures.length" class="notification is-danger is-light">
            <div>
              <strong>Failed to update: {{failures.length}}</strong>
              <br/>
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <td>wholesaleModel</td>
                    <td>Reason</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(s, i) in failures" :key="'f-'+i">
                    <td>{{s.wholesaleModel}}</td>
                    <td>{{s.reason}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
          <div v-if="error" class="notification is-danger is-light">
            <button class="delete" @click="error=''"></button>
            {{error}}
          </div>
        </section>
        <footer class="modal-card-foot">
          <a class="button" @click="close">Close</a>
        </footer>
      </div>
    </div>
  </template>
    
  <script>
  import Datepicker from 'vuejs-datepicker'
  import { parse } from 'csv-parse/lib/sync'
  import dateFormat from 'dateformat'
  import { saveAs } from 'file-saver'
  import Excel from 'exceljs'
   
  export default {
    name: 'upload-wholesale-promotion-modal',
    components: {
      Datepicker
    },
    props: ['opened'],
    data () {
      return {
        error: '',
        waiting: false,
        priceType: 'Wholesale',
        store: 'Home Depot',
        country: 'US',
        startDate: new Date(),
        endDate: new Date(),
        fieldNames: ['channel', 'wholesaleModel', 'wholesalePrice', 'startDate'],
        success: '',
        successes: [],
        failures: [],
        excelFile: null,
        records: null,
      }
    },
    computed: {
      server () {
        return this.$store.state.config.server
      },
      dataConfig () {
        var config = {}
        if (this.priceType == 'Wholesale') {
          config.priceColName = 'wholesalePrice'
          config.fields = ['channel', 'wholesaleModel', 'wholesalePrice', 'startDate']
          config.successFields = ['wholesaleModel', 'reason']
          if (this.store == 'Home Depot') {
            config.channel = this.country == 'US' ? 'Home Depot' : 'Home Depot Canada'
            config.startRow = 2
            config.modelCol = 1
            config.priceCol = this.country == 'US' ? 4 : 11
          } else {
            config.channel = this.country == 'US' ? 'Wayfair' : 'Wayfair Canada'
            config.startRow = 4
            config.modelCol = 1
            config.priceCol = 4
          }
        } else {
          config.successFields = ['id', 'percentage', 'price', 'startDate', 'endDate']
          if (this.store == 'Home Depot') {
            config.fields = ['channel', 'wholesaleModel', 'promotionPrice', 'startDate', 'endDate']
            config.priceColName = 'promotionPrice'
            config.channel = this.country == 'US' ? 'Home Depot' : 'Home Depot Canada'
            config.startRow = 14
            config.modelCol = 1
            config.priceCol = 11
          } else {
            config.fields = ['channel', 'wholesaleModel', 'promotionPercentage', 'startDate', 'endDate']
            config.priceColName = 'promotionPercentage'
            config.channel = this.country == 'US' ? 'Wayfair' : 'Wayfair Canada'
            config.startRow = 5
            config.modelCol = 1
            config.priceCol = 'B2C Promotion Discount Percent (%)'
          }
        }
        return config
      },
      finalRecords () {
        if (this.records) {
          var rs = []
          for (var record of this.records) {
            var r = {...record}
            r.startDate = dateFormat(this.startDate, 'isoDate')
            if (r.endDate) {
              r.endDate = dateFormat(this.endDate, 'isoDate')
            }
            rs.push(r)
          }
          return rs
        }
        return null
      },
    },
    watch: {
      opened: function (val) {
        this.reset()
      },
      store: function (val) {
        this.reset()
      },
      country: function (val) {
        this.reset()
      },
      priceType: function (val) {
        this.reset()
      },
    },
    methods: {
      reset () {
        document.getElementById('myFileInput').value= null
        this.excelFile = null
        this.records = null
        this.successes = []
        this.failures = []
        this.success = ''
        this.error = ''
      },
      close () {
        this.$emit('closeUploadWholesalePromotionModal')
      },
      onExcelFileChange (e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.excelFile = files[0];
        var reader = new FileReader()
        var vm = this
        reader.onload = async function () {
          var data = reader.result
          const workbook = new Excel.Workbook();
          await workbook.xlsx.load(data)
          const worksheet = workbook.worksheets[vm.store == 'Home Depot' ? 0 : 1]
          var records = []
          var startRow = vm.dataConfig.startRow
          var modelCol = vm.dataConfig.modelCol
          var priceCol = vm.getPriceCol(vm.dataConfig.priceCol, worksheet)
          var channel = vm.dataConfig.channel
          var priceColName = vm.dataConfig.priceColName
          for (var i=startRow;i<=worksheet.actualRowCount;i++) {
            var row = worksheet.getRow(i)
            if (typeof(row.getCell(priceCol).value) != 'number') {
              continue
            }
            var model = row.getCell(modelCol).value.toString()
            var price = row.getCell(priceCol).value.toString()
            var record = {
              channel: channel,
              wholesaleModel: model,
              startDate: dateFormat(vm.startDate, 'isoDate')
            }
            record[priceColName] = price
            if (vm.priceType == 'Promotion') {
              record.endDate = dateFormat(vm.endDate, 'isoDate')
            }
            records.push(record)
          }
          vm.records = records
        }
        reader.readAsArrayBuffer(this.excelFile)
      },
      getPriceCol (priceCol, worksheet) {
        if (typeof(priceCol) != 'string') {
          return priceCol
        }

        for (var i=1;i<=worksheet.actualRowCount;i++) {
          var row = worksheet.getRow(i)
          var col = null
          row.eachCell(function(cell, colNumber) {
            if (cell.value.toString().includes(priceCol)) {
              col = colNumber
            }
          });
          if (col) {
            return col
          }
        }
      },
      uploadPrices () {
        if (!this.file || this.waiting) {
          return
        }
        this.waiting = true
        this.error = ''
  
        var vm = this
        var reader = new FileReader()
        reader.onload = function () {
          var text = reader.result
          var records = parse(text, {
            columns: true,
          })
          vm.sendUpdates(records)
        }
        reader.readAsText(this.file)
        
      },
      sendRecords () {
        var batches = []
        var batch = []
        for (var record of this.finalRecords) {
          var update = {}
          for (const fieldName of this.dataConfig.fields) {
            if (record[fieldName]) {
              update[fieldName] = record[fieldName].trim()
            }
          }
          batch.push(update)
          if (batch.length == 100) {
            batches.push(batch.slice())
            batch = []
          }
        }
        if (batch.length) {
          batches.push(batch)
        }
  
        var promises = []
        this.successes = []
        this.failures = []
        this.success = ''
        this.error = ''
        var url = this.priceType == 'Wholesale' ? '/myapp/batch-update-wholesale-prices/' : '/myapp/batch-update-promotions/'
        for (var updates of batches) {
          var message = {updates: updates}
          var promise = this.$http.post(this.server + url, message).then(resp => {
            this.successes = this.successes.slice().concat(resp.body.successes)
            this.failures = this.failures.slice().concat(resp.body.failures)
          }, err => {
            this.error = 'Some uploads failed.'
            console.log('One update request failed', err)
          })
          promises.push(promise)
        }
  
        Promise.all(promises).then(resp => {
          this.success = 'Process has been finished.'
          this.waiting = false
        }, err => {
          this.error = 'Some uploads failed.'
          this.waiting = false
        })
      },
    },
  }
  </script>
    
  <style scoped>
  .my-modal-card {
    width: 80%!important;
  }
  </style>